<template>
  <tr>
    <td class='border border-black p-8 uppercase text-center'>
      {{ signature.role }}
    </td>
    <td class='border border-black p-8 font-semibold uppercase text-center'>{{ signature.name }}</td>
    <td v-if='signature.mine && notSignature' class='border border-black p-8 text-center'>
      해당사항 없음<br>(담당자)
    </td>
    <td v-else class='border border-black p-8 text-center'>
      <img :src='`${signature.signImage}`' v-if='signature.signStatus' class='w-2/3 inline-block' />
    </td>
  </tr>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'ReportsSignPageRow',
  props: ['signature', 'approvalRequest'],
  computed: {
    ...mapGetters('users', [
      'currentUserId',
    ]),
    ...mapState('approvals', [
      'approvalRequests',
      'requestTypes',
    ]),
    ...mapState('users', [
      'users',
    ]),
    approvalType () {
      return this.approvalRequest ? this.approvalRequest.approval_type : ''
    },
    currentRequestType () {
      var requestType = this.requestTypes.find(type => type.name === this.approvalType)
      return requestType ? requestType : ''
    },
    notSignature () {
      return (this.isCompliance || this.isCheckListReport || this.isAIRiskReport)
    },
    isInvestmentReport () {
      return this.approvalType === '투자심의위원회' || this.approvalType === '투심위의사록'
    },
    isComplianceReport () {
      return this.approvalType === '준법감시보고서'
    },
    isCheckListReport () {
      return this.approvalType === '투자조건 체크리스트'
    },
    isAIRiskReport () {
      return this.approvalType === '대체투자AI_리스크등급 보고서'
    },
    newReportTitle () {
      return this.ReportTitle ? this.ReportTitle : this.title
    },
    newReportDate () {
      return this.ReportDate ? this.ReportDate : dayjs().format('YYYY년 MM월 DD일')
    },
    isAllApporved () {
      var pendings = this.approvalRequest.approvalResponses.filter(approvalResponse => approvalResponse.status !== 'approved')
      return pendings.length === 0
    },
    // approved_user () {
    //   return this.users.find(user => user.id === approved.user_id)
    // },
    isManager () {
      return this.approved_user ? this.approved_user.roles.includes('manager') : false
    },
    isCompliance () {
      return this.approved_user ? this.approved_user.roles.includes('co') : false
    },
    isRiskManager () {
      return this.approved_user ? this.approved_user.roles.includes('risk') : false
    },
    required () {
      return this.approvalRequest.approvalResponses.map(approved => {
        return {
          name: approved.user_name,
          role: this.role(approved),
          signStatus: approved.status === 'approved',
          mine: approved.user_id === this.currentUserId,
          signImage: approved.sign_image_url
        }
      })
    },
    RequiredSignatures () {
      var required = []

      if (this.approvalRequest) {
        required = this.approvalRequest.approvalResponses.map(approved => {
          return {
            name: approved.user_name,
            role: this.role(approved),
            signStatus: approved.status === 'approved',
            mine: approved.user_id === this.currentUserId,
            signImage: approved.sign_image_url
          }
        })

        if (this.isComplianceReport || this.isCheckListReport) {
          required.push({
            name: '',
            role: '집 합 투 자 업 자',
            signStatus: this.isAllApporved,
            signImage: ''
          })
        }
      }

      return required
    },
  },
  methods: {
    ...mapActions('approvals', [
      'getApprovalRequestsForInvestment',
    ]),
    isApproved(status) {
      return status === 'approved'
    },
    isMine(userId) {
      return userId === this.currentUserId
    },
    role (approved) {
      var user = this.users.find(user => user.id === approved.user_id)
      var isManager = user ? user.roles.includes('manager') : false
      var isCompliance = user ? user.roles.includes('co') : false
      var isRiskManager = user ? user.roles.includes('risk') : false

      if (this.isInvestmentReport) {
        return isCompliance ? '간사' : '심의위원'
      } else {
        if (isCompliance) {
          return '준법감시인'
        } else {
          if (this.isComplianceReport) {
            if (this.isMine(approved.user_id)) {
              return '작성자'
            }
            else if (isManager) {
              return '핵심운용인력'
            } else {
              return ''
            }
          } else if (this.isAIRiskReport) {
            if (isRiskManager) {
              return '위험관리책임자'
            } else {
              return ''
            }
          }
        }
      }
    },
  },
  mounted () {
    this.getApprovalRequestsForInvestment(parseInt(this.investment_id))
  }
}
</script>
